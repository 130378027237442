import { getSystemFilesURL } from "@/helpers";

export const quotesTutorial = [
  {
    title: "Create a Quote",
    steps: [
      {
        title: "Step 1",
        text: `Select "Quotes" from the navigation menu.`,
        imgUrl: getSystemFilesURL("go-to-quotes-navigation")
      },
      {
        title: "Step 2",
        text: `Click the "Create New Quote" button to begin the process.`,
        imgUrl: getSystemFilesURL("create-new-quote-button")
      },
      {
        title: "Step 3",
        text: `Select the line of business under which you would like to create a quote.`,
        imgUrl: getSystemFilesURL("select-line-of-business")
      },
      {
        title: "Step 4.1",
        text: `Enter the details of the insured, the distance of the risk from the nearest fire hydrant and fire station, and select "Lookup Address" to fill in the address of the insured.`,
        imgUrl: getSystemFilesURL("lookup-address")
      },
      {
        title: "Step 4.2",
        text: `Select the matching address from the list of suggested addresses and click "Validate Address" to verify the address.`,
        imgUrl: getSystemFilesURL("validate-address")
      },
      {
        title: "Step 4.3",
        text: `If more than one territory is found for the address, select a territory that matches, precisely, where the property is located to proceed to the Quote form.`,
        imgUrl: getSystemFilesURL("select-territory")
      },
      {
        title: "Step 5.1",
        text: `Welcome to the new and improved quotes form! Fill out the details of the risk to be insured. Ensure all fields marked as required are properly filled. Required fields are specified in two ways :
            The red asterisk symbol right near the field (*).
            Or a warning that states the field is required
            You will only be able to proceed if all required fields are filled.
            After you have finished filling out the form, click the "Save Quote" button to save your quote.`,
        imgUrl: getSystemFilesURL("property-information")
      },
      {
        title: "Step 5.2",
        text: `After saving your quote, you can go back to the quotes page and view your quote, and edit it or get a rating for it later on. Or if you would like to rate your quote, click the "Rate Quote" button.`,
        imgUrl: getSystemFilesURL("rate-quote")
      },
      {
        title: "Step 6",
        text: `On the rating page, you can see all the rates for the various companies that were selected on the previous page. Select your preferred company and corresponding form type to proceed to the next step.`,
        imgUrl: getSystemFilesURL("select-rating")
      },
      {
        title: "Step 6",
        text: `Answer all the questions on the Underwriting Qualifications page. Note that all the questions on this page are mandatory and every option you select will be verified by the underwriter.`,
        imgUrl: getSystemFilesURL("underwriter-questions")
      }
    ]
  },
  {
    title: "Edit, Print, or Delete a Quote",
    steps: [
      {
        title: "Step 1",
        text: `Note that you can only edit a quote if it has not been bound yet. Also, if it has been rated, you'll need to re-rete the quote after editing it. To edit, delete, or print a quote, go to the "Quotes" page from the navigation menu`,
        imgUrl: getSystemFilesURL("go-to-quotes-navigation")
      },
      {
        title: "Step 2",
        text: `You'll see a list of all the quotes you have created. Click on any of the quotes to edit it. Alternately, you can hover on the menu icon on the quote you want to edit and you'll find "Edit" in the popup menu. Any of the two will take you to the last step you completed when you created or last edited the quote. From here, you can proceed to bind the quote to a policy.`,
        imgUrl: getSystemFilesURL("edit-quote")
      }
    ]
  }
];
