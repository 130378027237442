import { getSystemFilesURL } from "@/helpers";

export const policyTutorial = [
  {
    title: "Make a Policy Inquiry",
    steps: [
      {
        title: "Step 1",
        text: `Select "Policy Enquiry" from the navigation menu, under the "Policy" option.`,
        imgUrl: getSystemFilesURL("policy-enquiry-menu")
      },
      {
        title: "Step 2",
        text: `Select the company. Enter the policy prefix and the policy number. After the fields have been filled. Click on the "View Policy Information" button to proceed`,
        imgUrl: getSystemFilesURL("policy-enquiry-form")
      },
      {
        title: "Step 3",
        text: `Welcome to the policy enquiry page! You can view the details of the enquiry below. Yoy may also print the policy from the "Actions" dropdown.`,
        imgUrl: getSystemFilesURL("policy-enquiry-view")
      }
    ]
  }
];
