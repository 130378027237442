import { getSystemFilesURL } from "@/helpers";

export const claimsTutorial = [
  {
    title: "File a Claim",
    steps: [
      {
        title: "Step 1",
        text: `Select "File a Claim" from the menu under Claims`,
        imgUrl: getSystemFilesURL("file-a-claim-navigation")
      },
      {
        title: "Step 2",
        text: `Select the company, and the policy prefix. Enter the policy number, and the loss date. After the fields have been filled. Click "Create Loss Form" to begin filing your claim.`,
        imgUrl: getSystemFilesURL("file-a-claim-enter-policy-details")
      },
      {
        title: "Step 3",
        text: `Welcome to the new and improved claims form! Fill out the details of your claims application. Ensure all fields marked as required are properly filled. Required fields are specified in two ways :
            The red asterisk symbol right near the field (*).
            Or a warning that states the field is required
            You will only be able to proceed if all required fields are filled.
            After you have finished filling out the form, click the "Submit" button to save your claim. (3)
            3.4
            Alternatively, you may click the "Cancel" button if you decide to not proceed with the claim. (4)`,
        imgUrl: getSystemFilesURL("file-a-claim-fill-in-claim-form-details")
      }
    ]
  },
  {
    title: "File a Claim On the Claims Page",
    steps: [
      {
        title: "Step 1",
        text: `Click the "File a Claim" button that`,
        imgUrl: getSystemFilesURL("file-a-claim-by-using-file-a-claim-button")
      },
      {
        title: "Step 2",
        text: `Select the company, and the policy prefix. Enter the policy number, and the loss date. After the fields have been filled. Click "Create Loss Form" to begin filing your claim.
          Proceed with the steps in the "File a Claim" section above.`,
        imgUrl: getSystemFilesURL("file-a-claim-enter-policy-details")
      }
    ]
  },
  {
    title: "Lookup Claim By Claim Number",
    steps: [
      {
        title: "Step 1",
        text: `Select "Claims Status Lookup" from the menu under Claims`,
        imgUrl: getSystemFilesURL("lookup-claim-by-claim-number")
      },
      {
        title: "Step 2",
        text: `Make sure you have the "Claim Number" option selected.Enter the claim number into the field. The claim number is a 5-digit number. After you enter the claim number, click "Lookup Claim" button to lookup the claim`,
        imgUrl: getSystemFilesURL("lookup-claim")
      },
      {
        title: "Step 3",
        text: `View the results of your claim lookup. You are done!`,
        imgUrl: getSystemFilesURL("lookup-claim-by-claim-number-result")
      }
    ]
  },
  {
    title: "Lookup Claim By Policy Number",
    steps: [
      {
        title: "Step 1",
        text: `Select "Claims Status Lookup" from the menu under Claims`,
        imgUrl: getSystemFilesURL("lookup-claim-by-claim-number")
      },
      {
        title: "Step 2",
        text: `Make sure you have the "Policy Number" option selected. Select the policy prefix. Enter the policy number. After you enter the policy number, click "Lookup Claim" button to lookup the claim`,
        imgUrl: getSystemFilesURL("lookup-claim-by-policy-number")
      },
      {
        title: "Step 3",
        text: `View the results of your claim lookup. This will be a list of claims filed under the policy number. You can click the view button to view a claim. You are done! `,
        imgUrl: getSystemFilesURL("lookup-claim-by-policy-number-result")
      }
    ]
  }
];
