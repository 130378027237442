<template>
  <div class="policy pt-4 pb-12">
    <el-collapse @change="handleChange" accordion>
      <el-collapse-item
        v-for="(topic, index) in onboardingTopics"
        :title="topic.title"
        :key="index"
        :name="index + 1"
      >
        <div v-for="(step, idx) in topic.steps" :key="idx">
          <h4 class="font-medium text-lg pt-2">
            {{ step.title }}
          </h4>
          <p class="py-2">{{ step.text }}</p>
          <img v-if="step.imgUrl" :src="step.imgUrl" :alt="step.title" />
        </div>
      </el-collapse-item>
    </el-collapse>
    <div id="onboarding-switch" class="py-4">
      <!-- <el-switch
        v-if="tutorialCompletionAcknowledgementShown"
        class="py-4"
        @change="updateUserCompletedTutorials"
        :disabled="disableButton"
        v-model="hasViewedTutorial"
        active-color="#13ce66"
        inactive-color="#e8e8e8"
        active-text="I acknowledge that I have thoroughly gone through every section of this tutorial"
      /> -->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { userMapGetters, userMapMutations } from "@/store/modules/user";
import { billingTutorial } from "./billingTutorial";
import { claimsTutorial } from "./claimsTutorial";
import { quotesTutorial } from "./quotesTutorial";
import { policyTutorial } from "./policyTutorial";
import { authMapGetters } from "@/store/modules/auth";
export default Vue.extend({
  name: "OnboardingContent",
  data() {
    return {
      hasViewedTutorial: false,
      disableAcknowledgeButton: false,
      viewedTutorials: [],
      error: ""
    };
  },
  props: {
    context: {
      type: String,
      required: true
    }
  },
  destroyed() {
    this.SET_EDIT_USER("");
  },
  methods: {
    ...userMapMutations(["SET_EDIT_USER"]),
    handleChange(tutorial) {
      if (!tutorial) return;
      if (!this.viewedTutorials.includes(tutorial)) {
        this.viewedTutorials.push(tutorial);
      }
    },
    updateUserCompletedTutorials(hasCompleted) {
      if (hasCompleted && !this.disableAcknowledgeButton) {
        this.updateUser({
          id: this.$getCurrentUser._id,
          update: {
            isOnboarding: true,
            onboardingTopicsCompleted: [
              ...this.onboardingTopicsCompleted,
              this.context
            ]
          }
        })
          .then(() => {
            this.$appNotifySuccess("Tutorial completed successfully");
            this.disableAcknowledgeButton = true;
          })
          .catch(error => {
            this.$appNotifyError(
              `Sorry, we couldn't save your progress. Please try again later.`
            );
            this.hasViewedTutorial = false;
            this.$bugSnagClient.notify(error);
          });
      }
    }
  },
  computed: {
    ...authMapGetters(["getCurrentUserId"]),
    ...userMapGetters(["getEditingUser"]),
    onboardingTopicsCompleted() {
      return this.getEditingUser?.onboardingTopicsCompleted || [];
    },
    tutorialCompletionAcknowledgementShown() {
      let switchShown = false;
      if (this.hasViewedTutorialInThePast) {
        switchShown = true;
      } else {
        switchShown =
          this.viewedTutorials.length === this.onboardingTopics.length;
      }
      return switchShown;
    },
    hasViewedTutorialInThePast() {
      return this.onboardingTopicsCompleted.includes(this.context);
    },
    disableButton() {
      return this.disableAcknowledgeButton || this.hasViewedTutorialInThePast;
    },
    onboardingTopics() {
      let topics = [];
      switch (this.context) {
        case "quotes":
          topics = quotesTutorial;
          break;
        case "claims":
          topics = claimsTutorial;
          break;
        case "billing":
          topics = billingTutorial;
          break;
        case "policy":
          topics = policyTutorial;
          break;
        default:
          break;
      }
      return topics;
    }
  },
  watch: {
    getEditingUser: {
      deep: true,
      immediate: false,
      handler(user) {
        if (user?.onboardingTopicsCompleted) {
          if (user?.onboardingTopicsCompleted?.includes(this.context)) {
            this.hasViewedTutorial = true;
          }
        }
      }
    }
  }
});
</script>
<style lang="scss" scoped>
#onboarding-switch .el-switch {
  flex-wrap: wrap;
}
</style>
